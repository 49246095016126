import React, { useState, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const CustomSelectField = ({ 
  id, 
  label, 
  fullWidth, 
  initialValue, 
  items, 
  parentHandleChange 
}) => {

  const classes = useStyles();

  const [value, setValue] = useState(initialValue);

  const renderItems = useCallback(
    () => (
      items.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))
    ),
    [items]
  );

  return (
    <TextField
      select
      id={id}
      label={label}
      value={value}
      fullWidth={fullWidth}
      classes={{ root: classes.customSelectFieldRoot }}
      onChange={event => {
        setValue(event.target.value);
        parentHandleChange(event.target.value);
      }}
      SelectProps={{
        MenuProps: {
          classes: {
            paper: classes.selectScreen
          }
        }
      }}
    >
      {renderItems()}
    </TextField>
  );

};

export default CustomSelectField;
