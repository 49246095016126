import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import MenuItem from "@material-ui/core/MenuItem";

import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";

import { renderError } from "./common";

export const InputOutlined = React.memo(
  ({
    inputLabel,
    input,
    label,
    margin = "normal",
    meta: { touched, error },
    controlStyle = {},
    withFormControl = true,
    ...custom
  }) => {
    const hasError = (error || false) !== false;

    if (withFormControl) {
      return (
        <FormControl
          margin={margin}
          error={touched && hasError}
          fullWidth
          style={controlStyle}
        >
          <TextField
            {...input}
            autoComplete={false}
            variant="outlined"
            {...custom}
            label={inputLabel}
            error={hasError}
          />
          {renderError({ touched, error })}
        </FormControl>
      );
    }

    return <TextField {...input} {...custom} error={hasError} />;
  }
);

export const SelectOutlined = React.memo(
  ({
    inputLabel,
    input,
    label,
    meta: { touched, error },
    withFormControl = true,
    ...custom
  }) => {
    const hasError = (error || false) !== false;

    return (
      <FormControl
        variant="outlined"
        margin="normal"
        error={touched && hasError}
        fullWidth
      >
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          // value={values.age}
          // onChange={handleChange}
          input={<OutlinedInput name="age" id="outlined-age-simple" />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const PrimaryButton = React.memo(
  ({
    fullWidth = true,
    variant = "contained",
    color = "primary",
    className = null,
    loading = false,
    type = "button",
    text
  }) => {
    return (
      <div className={className}>
        <Button
          type={type === "submit" ? "submit" : "button"}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          disabled={loading}
        >
          {loading ? <CircularProgress size={26} /> : text}
        </Button>
      </div>
    );
  }
);
