// Types
export const Types = {
  SET_ROLES_LOADING: 'SET_ROLES_LOADING', 
  ASYNC_FETCH_ROLES: 'ASYNC_FETCH_ROLES', 
  ASYNC_FETCH_PAGINATED_ROLES: 'ASYNC_FETCH_PAGINATED_ROLES', 
  SUCCESS_FETCH_ROLES: 'SUCCESS_FETCH_ROLES',
  FAIL_FETCH_ROLES: 'FAIL_FETCH_ROLES', 
  ASYNC_POPULATE_ROLE_FORM: 'ASYNC_POPULATE_ROLE_FORM', 
  ASYNC_CREATE_ROLE: 'ASYNC_CREATE_ROLE',
  ASYNC_UPDATE_ROLE: 'ASYNC_UPDATE_ROLE',
  ASYNC_DELETE_ROLE: 'ASYNC_DELETE_ROLE'
};

const INITIAL_VALUES = {
  data: [],
  loading: false,
};

// Reducers
export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_ROLES_LOADING:
      return { ...state, loading: action.payload.loading, data: action.payload.loading ? [] : [...state.data] };
    case Types.SUCCESS_FETCH_ROLES:
      return { ...state, data: action.payload.roles };
    case Types.FAIL_FETCH_ROLES:
      return { ...state, ...INITIAL_VALUES };
    default:
      return state;
  }
};

// Action Creators
export const fetchRoles = () => ({
  type: Types.ASYNC_FETCH_ROLES
});

export const fetchPaginatedRoles = (page, perPage) => ({
  type: Types.ASYNC_FETCH_PAGINATED_ROLES,
  payload: {
    page, perPage
  }
});

export const populateRoleForm = roleId => ({
  type: Types.ASYNC_POPULATE_ROLE_FORM,
  payload: {
    roleId
  }
});

export const createRole = formData => ({
  type: Types.ASYNC_CREATE_ROLE,
  payload: {
    formData
  }
});

export const updateRole = (roleId, formData) => ({
  type: Types.ASYNC_UPDATE_ROLE,
  payload: {
    roleId,
    formData
  }
});

export const deleteRole = roleId => ({
  type: Types.ASYNC_DELETE_ROLE,
  payload: {
    roleId
  }
});
