const userFormValidate = (values, props) => {
  const errors = {};

  if (!values.name)
    errors.name = 'Campo obrigatório';

  if (!values.email)
    errors.email = 'Campo obrigatório';
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+[A-Z]{2,4}$/i.test(values.email))
    errors.email = 'Insira um email válido';

  if (!props.userId && !values.password)
    errors.password = 'Campo obrigatório';

  if (values.phones) {
    const phonesArrayErrors = [];

    values.phones.forEach((phone, index) => {

      if (phone && isNaN(phone))
        phonesArrayErrors[index] = 'O campo deve conter apenas números';
    });

    errors.phones = phonesArrayErrors;
  }

  return errors;
};

export default userFormValidate;
