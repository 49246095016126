import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import CustomAppBar from '../components/CustomAppBar';
import Acl from '../components/Acl';
import CustomContainer from '../components/CustomContainer';
import Header from '../components/Header';
import { fetchPermissions } from '../store/ducks/auth';
import { updateCurrentRoute } from '../store/ducks/route';

const AuthenticatedRoute = ({ 
  component: Component, 
  updateCurrentRoute, 
  fetchPermissions, 
  computedMatch: { url }, 
  token, 
  permission, 
  headerTitle,
  breadcrumbsConfig, 
  ...rest 
}) => {

  useEffect(() => {

    updateCurrentRoute(url);

  }, [updateCurrentRoute, url]);

  useEffect(() => {

    if (token)
      fetchPermissions();

  }, [token, fetchPermissions])

  return (
    <Route 
      {...rest}
      render={routeProps => 
        token ? 
        (
          <>
            <CustomAppBar />
            <Acl permission={permission} showUnauthorized>
              <CustomContainer>
                <Header title={headerTitle} breadcrumbsConfig={breadcrumbsConfig} />
                <Component {...routeProps} />
              </CustomContainer>
            </Acl>
          </>
        ) 
        :
        (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );

};

const mapStateToProps = state => ({ 
  token: state.auth.token
});

const mapDispatchToProps = {
  updateCurrentRoute,
  fetchPermissions
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(AuthenticatedRoute);
