import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logout } from '../../store/ducks/auth';
import useStyles from './styles';
import CustomDrawer from './CustomDrawer';

const CustomAppBar = ({ user, logout }) => {

  const classes = useStyles();

  const [drawerOpened, setDrawerState] = useState(false);

  const firstName = useCallback(() => (user || '').split(' ')[0] , [user],)
  
  return (
    <>
      <AppBar position="static" classes={{ root: classes.appBarRoot }}>
        <Toolbar>
          <IconButton 
            edge="start" 
            color="inherit" 
            className={classes.menuButton}
            onClick={() => setDrawerState(!drawerOpened)}
          >
            {!drawerOpened && <MenuIcon />}
            {drawerOpened && <CloseIcon />}
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            UAdmin
          </Typography>
          
          <Box className={classes.userConfig}>
            <Typography variant="subtitle1">
              Bem vindo(a), {firstName()} 
            </Typography>
            <IconButton 
              color="inherit" 
              className={classes.exitButton} 
              onClick={logout}
            >              
              <ExitToAppIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <CustomDrawer 
        opened={drawerOpened}
        firstName={firstName}
        parentHandleItemClick={() => setDrawerState(!drawerOpened)}
        parentHandleToggle={() => setDrawerState(!drawerOpened)}
      />
    </>
  );
  
};

const mapStateToProps = state => ({ 
  user: state.auth.user.name 
});

const mapDispatchToProps = {
  logout
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(CustomAppBar);
