import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  containerFile: {
    //background: '#999',
    padding: "10px",
    border: "1px dashed #AAA",
    boxShadow: "none"
  },
  fileInfo: {
    float: "right",
    marginTop: "8px",
    marginRight: "8px",
    color: theme.palette.common.black
  }
}));
