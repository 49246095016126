import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBarRoot: {
    zIndex: 1400
  }, 

  title: {
    flexGrow: 1,
    fontWeight: 400
  },

  menuButton: {
    marginRight: '10px'
  },

  userConfig: {
    display: 'flex',
    alignItems: 'center'
  },

  exitButton: {
    marginLeft: '30px'
  },

  exitIcon: {
    marginLeft: '10px'
  }
}));
