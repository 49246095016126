import api from './api';

export const fetchApplications = async params => {
  try {

    const { data } = await api().get(
      '/applications',
      { params }
    );

    return data;

  } catch (err) {

    console.error(err);

    return err.response.data;

  }
};