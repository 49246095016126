import React, { useEffect, useCallback } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomSelectField from "../CustomSelectField";
import Grid from '@material-ui/core/Grid';

import { updateSelectedCustomer } from "../../store/ducks/auth";
import { connect } from "react-redux";
import { setCustomers, fetchCustomers } from "../../store/ducks/customer";
import { history } from "../../routes";
import useStyles from './styles';

const SelectCustomer = ({
  selectedCustomerId,
  customers,
  url,
  setCustomers,
  fetchCustomers,
  updateSelectedCustomer,
  user
}) => {

  const classes = useStyles();

  const configCustomersForSelect = useCallback(
    () => (
      customers.map(customer => ({
        label: customer.name,
        value: customer.id
      }))
    ),
    [customers]
  );
  
  useEffect(() => {
    if (user.isInternalAdmin) {
      fetchCustomers();
    } else {
      const { childs, ...customer } = user.customer;
      const customers = [customer, ...childs];
      setCustomers(customers);        
    }
  }, [user.isInternalAdmin, fetchCustomers, user.customer, setCustomers, updateSelectedCustomer, selectedCustomerId]);

  const useFullWidth = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const customersForSelect = configCustomersForSelect();

  return customersForSelect.length > 1 && (
    <Grid
      item
      xs={12}      
      classes={{ root: classes.selectFieldContainer }}
    >
      <CustomSelectField
        id="select-customer"
        label="Cliente"
        fullWidth={useFullWidth}
        initialValue={selectedCustomerId}
        items={customersForSelect}
        parentHandleChange={value => {
          updateSelectedCustomer(value);
          history.push(url.replace(/[\d]+/, value));
        }}
      />
    </Grid>
  );
};

const mapStateToProps = state => ({
  selectedCustomerId: state.auth.selectedCustomerId || state.auth.user.customerId,
  customers: state.customers.data,
  user: state.auth.user
});

const mapDispatchToProps = { 
  updateSelectedCustomer, 
  setCustomers, 
  fetchCustomers 
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCustomer);
