import { takeLatest, take, all, put, call, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { Types as RoleTypes } from '../ducks/role';
import { Types as TableTypes } from '../ducks/table';
import { Types as CustomComponentsTypes } from '../ducks/customComponents';
import { Types as ApplicationTypes } from '../ducks/application';
import { fetchRoles, findRole, createRole, updateRole, deleteRole } from '../../services/role';
import { history } from '../../routes';

function* asyncFetchRoles() {
  
  yield put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const params = { customerId };

  const { error, data } = yield call(fetchRoles, params);

  if (error)
    yield put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } });

  yield all([
    put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } }),
    put({ type: RoleTypes.SUCCESS_FETCH_ROLES, payload: { roles: data } })
  ]);

};

function* asyncFetchPaginatedRoles({ payload }) {

  yield put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  let { page = 1, perPage = 10 } = payload;

  const searchTerm = yield select(state => state.customComponents.searchbarTerm);

  let params = { customerId, page, perPage, searchTerm };

  let { lastPage, data, total, error } = yield call(fetchRoles, params);

  if (error) {

    yield put({ type: RoleTypes.FAIL_FETCH_ROLES });

  } else {

    if (lastPage < page) {
      page = lastPage;

      params = { ...params, page };
  
      ({ data, total } = yield call(fetchRoles, params));
    }

    yield all([
      put({ type: TableTypes.UPDATE_TABLE_PAGINATION, payload: { totalRows: total, currentPage: page, perPage, lastPage } }),
      put({ type: RoleTypes.SUCCESS_FETCH_ROLES, payload: { roles: data } }), 
      put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } })
    ]);

  }

};

function* getPermissionIds(formPermissions) {

  const stateApplications = yield select(state => state.applications.data);

  const statePermissions = 
    stateApplications
      .map(application => application.permissions)
      .flat();

  const selectedPermissions = statePermissions.filter((role, index) => formPermissions[index]);

  return selectedPermissions.map(permission => permission.id);

};

function* asyncPopulateRoleForm({ payload }) {

  let initialValues = {};

  yield put({ type: ApplicationTypes.ASYNC_FETCH_APPLICATIONS });

  yield take(ApplicationTypes.SUCCESS_FETCH_APPLICATIONS);

  const stateApplications = yield select(state => state.applications.data);

  const statePermissions = 
    stateApplications
      .map(application => application.permissions)
      .flat();

  const { roleId } = payload;

  if (roleId) {

    const { data: { label, permissions } } = yield call(findRole, roleId);

    const formPermissions = statePermissions.map(statePermission => {
      return permissions.find(rolePermission => rolePermission.id === statePermission.id) ? true : false;
    });

    initialValues = { label, permissions: formPermissions };

  } else {

    const formPermissions = statePermissions.map(() => false);

    initialValues = { permissions: formPermissions  };

  }

  yield put(initialize('role', initialValues));

};

function* asyncCreateRole({ payload: { formData } }) {

  yield put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const permissionIds = yield call(getPermissionIds, formData.permissions);

  const { error } = yield call(createRole, { ...formData, name: formData.label, customerId, permissions: permissionIds });

  if (error) {

    yield all([
      put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } }),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: error.message } }),
    ]);

  } else {

    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Cargo cadastrado com sucesso' } }),
      call(history.push, `/customers/${customerId}/roles`),
      put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } })
    ]);

  }
  
};

function* asyncUpdateRole({ payload: { roleId, formData } }) {

  yield put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const permissionIds = yield call(getPermissionIds, formData.permissions);

  const { error } = yield call(updateRole, roleId, { ...formData, name: formData.label, customerId, permissions: permissionIds });

  if (error) {

    yield all([
      put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } }),
      put({ Type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: error.message } }),
    ]);

  } else {

    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Cargo atualizado com sucesso' } }),
      call(history.push, `/customers/${customerId}/roles`),
      put({ type: RoleTypes.SET_ROLES_LOADING, payload: { loading: false } })
    ]);

  }

};

function* asyncDeleteRole({ payload: { roleId } }) {

  const { page, perPage } = yield select(state => ({
    page: state.table.currentPage,
    perPage: state.table.perPage
  }));

  const { error } = yield call(deleteRole, roleId);

  if (error) {

    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Não foi possível excluir o cargo selecionado' } }),
      put({ type: CustomComponentsTypes.HANDLE_DIALOG, payload: { open: false } })
    ])

  } else {

    yield all([
      put({ type: RoleTypes.ASYNC_FETCH_PAGINATED_ROLES, payload: { page, perPage } }),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Cargo excluído com sucesso' } }),
      put({ type: CustomComponentsTypes.HANDLE_DIALOG, payload: { open: false } })
    ]);

  }

};

export default function* roleSaga() {

  yield all([
    takeLatest(RoleTypes.ASYNC_FETCH_PAGINATED_ROLES, asyncFetchPaginatedRoles),
    takeLatest(RoleTypes.ASYNC_FETCH_ROLES, asyncFetchRoles), 
    takeLatest(RoleTypes.ASYNC_POPULATE_ROLE_FORM, asyncPopulateRoleForm), 
    takeLatest(RoleTypes.ASYNC_CREATE_ROLE, asyncCreateRole),
    takeLatest(RoleTypes.ASYNC_UPDATE_ROLE, asyncUpdateRole),
    takeLatest(RoleTypes.ASYNC_DELETE_ROLE, asyncDeleteRole)
  ]);

};
