import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import Login from '../index';
import { loginSSO } from '../../../store/ducks/auth';

const LoginSSO = ({ history, location, loginSSO }) => {

  const query = new URLSearchParams(location.search);

  useLayoutEffect(() => {

    if(!query.has('sso')) {
      history.push('/');
    } else if (query.has('sso') && query.has('access_token')) {
      loginSSO(query.get('sso'), query.get('access_token'));
    } else if (query.has('sso')) {
      window.location.href = `${process.env.REACT_APP_SSO_URL}/login?sso=${query.get('sso')}&redirect=${process.env.REACT_APP_URL_APP}/login/sso`;
    }

  });

  return <Login sso={true} />

};

const mapDispatchToProps = { loginSSO };

export default connect(
  null,
  mapDispatchToProps
)(LoginSSO);
