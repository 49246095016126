import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  roleRoot: {
  },

  roleContentContainer: {
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5]
  },

  selectFieldContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 20
  },

  rolesTableContainer: {
    borderTop: `1px solid ${theme.palette.divider}`
  },

  newRoleButtonContainer: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
}));
