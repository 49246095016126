import { takeLatest, put, all } from "redux-saga/effects";
import { Types } from "../ducks/alert";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function* toogleMessage() {
  yield put({
    type: Types.TOOGLE_MESSAGE
  });
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_TOOGLE_MESSAGE, toogleMessage)]);
}
