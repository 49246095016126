import React from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';

const Footer = () => {

  const classes = useStyles();

  return (
    <Box component="footer" className={classes.footerRoot}>
      &#169; Copyright. Desenvolvido por <Box component="span" className={classes.companyName}>unicadtec</Box>. Todos os direitos reservados.
    </Box>
  );
  
};

export default Footer;
