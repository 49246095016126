import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";
import { withStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { history } from '../../../routes/index';
import { updatePreviousRoute } from '../../../store/ducks/route';
import { fetchRoles } from "../../../store/ducks/role";
import { importUsers } from "../../../store/ducks/user";
import config from "../../../config";
import styles from "./styles";
import SelectCustomer from "../../../components/SelectCustomer";
import FileInput from "../../../components/FileInput";
import CustomCheckboxField from "../../../components/CustomCheckboxField";

const renderCheckboxFields = ({ fields }) => {
  return fields.map((field, index, rest) => {
    return (
      <Grid item xs={12} sm={6} key={index}>
        <Field
          name={`${field}.checked`}
          component={CustomCheckboxField}
          label={rest.get(index).role.label}
        />
      </Grid>
    )
  });
};

class UserImport extends Component {

  componentDidMount = () => {
    this.props.fetchRoles(); 
  }

  componentDidUpdate = (prevProps) => {    
    const { roles, selectedCustomerId } = this.props;

    if(roles.length > 0 && prevProps.roles.length === 0) {
      roles.forEach(({ id, label }, index) => {
        this.props.array.insert('roles', index, {
          checked: false, 
          role: { id, label }
        });
      })

      this.props.change('customer_id', selectedCustomerId);
    }

    if(selectedCustomerId !== prevProps.selectedCustomerId) {
      this.props.array.removeAll('roles');
      this.props.fetchRoles();
    }
  }

  componentWillUnmount() {
    this.props.updatePreviousRoute(this.props.match.url);
  }
  
  render() {
    const {
      classes,
      match,      
      handleSubmit,
      submitting,
      previousRoute,  
      roles,
      loadingRoles,
      importUsers
    } = this.props;

    const { url } = match;

    return (
      <form onSubmit={handleSubmit(importUsers)}>
        <Box className={classes.userControlRoot}>

          <Grid container classes={{ root: classes.userContentContainer }}>

            <Grid item sm={12}>
              <SelectCustomer url={url} />
            </Grid>

            <Grid
              item
              sm={6}
              style={{ paddingBottom: 20 }}
              classes={{ root: classes.containerPadding }}
            >
              <Grid container>
                <Grid item sm={12}>
                  <a
                    style={{ textDecoration: "none" }}
                    href={
                      config("passport_url") +
                      "/examples/importacao-de-usuarios.xlsx"
                    }
                    download
                  >
                    <Card className={classes.cardInverse}>
                      <Typography variant="h6">
                        Modelo de Arquivo para Importação
                      </Typography>
                      <Typography>
                        Clique aqui para fazer o download do modelo da planilha
                        para importação dos usuários.
                      </Typography>
                    </Card>
                  </a>
                </Grid>

                <Grid item sm={12} style={{ marginTop: 20 }}>
                  <Field
                    component={FileInput}
                    label="Selecione um Arquivo"
                    name="import_file"
                    accept=".csv,.xls,.xlsx"
                    type="file"
                    style={{ display: "none" }}
                  />
                </Grid>
{/* 
                <Grid item sm={12} style={{ marginTop: 20 }}>
                  <Field
                    name={'change_passport_first_access'}
                    component={CustomCheckboxField}
                    label={'Os usuários deverão alterar a senha após o primeiro acesso ?'}
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item sm={6} style={{ borderLeft: "1px solid #DDD" }}>
              <Box className={classes.formSubtitleContainer}>
                <Typography classes={{ root: classes.formSubtitle }}>
                  Selecione os cargos a serem atribuídos ao novo usuário
                </Typography>
              </Box>

              <Divider />

              <div className={classes.containerPadding}>
                {loadingRoles ? (
                   <Grid container spacing={3} justify="center" alignItems="center" style={{ height: 160 }} >
                      <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Grid container spacing={3} >
                    {roles.length <= 0 ? (
                      <Typography
                        variant="subtitle2"
                        style={{ color: "rgba(0, 0, 0, 0.54)", padding: 15 }}
                      >
                        Não há cargos disponíveis para o cliente selecionado
                      </Typography>
                    ) : (                   
                      <FieldArray name="roles" component={renderCheckboxFields} />
                    )}
                  </Grid>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{ borderTop: "1px solid #DDD" }}
            >
              <Grid item xs={6} classes={{ root: classes.buttonContainer }}>
                {!submitting && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: 20 }}
                    classes={{ root: classes.button }}
                    className={classes.roundedButton}
                    onClick={() => history.push(previousRoute)}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={submitting}
                  classes={{ root: classes.button }}
                  className={classes.roundedButton}
                >
                  {submitting ? <CircularProgress size={20} /> : 'Importar'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

UserImport = withStyles(styles)(UserImport)

const mapStateToProps = state => ({
  previousRoute: state.route.previousRoute, 
  selectedCustomerId: state.auth.selectedCustomerId,
  roles: state.roles.data,
  loadingRoles: state.roles.loading
});

const mapDispatchToProps = { updatePreviousRoute, fetchRoles, importUsers };

UserImport = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserImport);

export default reduxForm({
  // initialValues: {
  //   change_passport_first_access: false
  // },
  form: "FORM_IMPORT_USER"
})(UserImport);
