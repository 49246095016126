import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import useStyles from './styles';

const CustomBreadcrumbs = ({ breadcrumbsConfig, match: { params } }) => {

  const classes = useStyles();

  const user = useSelector(state => state.auth.user);

  const configBreadcrumbs = useCallback(
    () => {
      const paramsKeys = Object.keys(params);

      const breadcrumbs = breadcrumbsConfig.map(breadcrumbConfig => {

        const pathWithParams = paramsKeys.reduce((breadcrumbPath, paramKey) => {
          return breadcrumbPath.replace(paramKey, params[paramKey]);
        }, breadcrumbConfig.path);

        return { ...breadcrumbConfig, path: pathWithParams };

      });

      return breadcrumbs.filter(breadcrumb => {
        if (breadcrumb.label === 'Clientes')
          if(!user.isInternalAdmin)
            return false;
    
        return true;
      })
    }, 
    [breadcrumbsConfig, user, params]
  );

  const breadcrumbs = configBreadcrumbs();

  return (
    <Breadcrumbs separator=">" classes={{ separator: classes.separator }}>
      {breadcrumbs.map((breadcrumb, index) => (
        <Link 
          variant="body2"
          key={index} 
          component={RouterLink}
          to={breadcrumb.path}
          classes={{ root: classes.link }}
        >
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default withRouter(CustomBreadcrumbs);
