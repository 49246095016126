import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  searchBarTextField: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.secondary.main
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main
    },
    
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  }
}));
