import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  userRoot: {
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5]
  },

  usersTableContainer: {
    borderTop: `1px solid ${theme.palette.divider}`
  },

  userButtonContainer: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around'
    }
  },

  importUsersButton: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  }
}));
