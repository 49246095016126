import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { history } from '../../../../routes/index';
import useStyles from './styles';
import CustomTextField from '../../../../components/CustomTextField';
import CustomCheckboxField from '../../../../components/CustomCheckboxField';
import roleFormValidate from '../../../../validators/roleFormValidate';

let RoleForm = ({
  handleSubmit,
  valid, 
  textFields,
  applications,
  parentHandleSubmit
}) => {

  const classes = useStyles();
  
  const previousRoute = useSelector(state => state.route.previousRoute);
  const roleLoading = useSelector(state => state.roles.loading);

  const renderTextFields = useCallback(
    () => (
      textFields.map((field, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          key={index}
        >
          <Field
            component={CustomTextField}
            name={field.name}
            label={field.label}
            adornment={field.adornment}
          />
        </Grid>
      ))
    ), 
    [textFields]
  );

  const renderCheckboxFields = useCallback(
    ({ fields }) => {
      const fieldsNameIterator = fields.map(field => field).values();

      if (fields.getAll()) {
        return (
          <Grid
            item
            xs={12}
          >
            {
              applications.map((application, index) => (
                <React.Fragment key={index}>
                  {application.permissions.length > 0 && 
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography>{application.name}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                        {
                          application.permissions.map(permission => (
                            <Field
                              key={permission.id}
                              name={fieldsNameIterator.next().value}
                              component={CustomCheckboxField}
                              label={permission.label}
                            />
                          ))
                        }
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  }
                </React.Fragment>
              ))
            }
          </Grid>
        );
      } else {
        return null;
      }
    }, 
    [applications, classes.panelDetails]
  );

  return (
    <Grid
      container
      component="form"
      classes={{ root: classes.formContainer }}
      onSubmit={handleSubmit(parentHandleSubmit)}
    >
      <Grid
        item
        xs={12}
        md={6}
        classes={{ root: classes.formLeftSide }}
      >
        <Box className={classes.formSubtitleContainer}>
          <Typography classes={{ root: classes.formSubtitle }}>
            Entre com os dados do cargo
          </Typography>
        </Box>

        <Divider />

        <Grid
          container
          spacing={3}
          classes={{ root: classes.fieldsContainer }}
        >
          {renderTextFields()}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        classes={{ root: classes.formRightSide }}
      >
        <Box className={classes.formSubtitleContainer}>
          <Typography classes={{ root: classes.formSubtitle }}>
            Selecione as permissões a serem atribuídas ao cargo
          </Typography>
        </Box>

        <Divider />

        <Grid
          container
          classes={{ root: classes.fieldsContainer }}
        >
          {
            applications.map(application => application.permissions).flat() <= 0 &&
              <Typography variant="subtitle2" style={{ color: 'rgba(0, 0 ,0, 0.54)' }}>
                Não há permissões disponíveis para o cliente selecionado
              </Typography>
          }
          {
            applications.length > 0 &&
              <FieldArray
                name="permissions"
                applications={applications}
                classes={classes}
                component={renderCheckboxFields}
              />
          }
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justify="center"
      >
        <Grid
          item
          xs={6}
          classes={{ root: classes.buttonContainer }}
        >
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 20 }}
            classes={{ root: classes.button }}
            onClick={() => history.push(previousRoute)}
          >
            <Typography color="secondary" variant="button">Cancelar</Typography>
          </Button>
          <Button
            variant="contained"
            color={!roleLoading ? "secondary" : 'default'}
            type="submit"
            disabled={!valid}
            classes={{ root: classes.button }}
          >
            {roleLoading &&
              <>
                <CircularProgress color="secondary" size={20} style={{ marginRight: 7 }}/>
                <Typography color="secondary" variant="button">Salvando</Typography>
              </>
            }
            {!roleLoading &&
              <Typography variant="button">Salvar</Typography>
            }
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default reduxForm({
  form: 'role',
  validate: roleFormValidate, 
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(RoleForm);
