import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const CustomDialog = ({ parentHandleClose }) => {

  const classes = useStyles();

  const open = useSelector(state => state.customComponents.dialogOpen);

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Você tem certeza?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Você realmente quer excluir este registro? Este processo não pode ser desfeito
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button 
          color="primary"
          classes={{ root: classes.actionButton }}
          onClick={() => parentHandleClose('Cancelar')}
        >
          Cancelar
        </Button>
        <Button 
          classes={{ root: `${classes.actionButton} ${classes.deleteButton}` }} 
          onClick={() => parentHandleClose('Excluir')}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  ); 
};

export default CustomDialog;
