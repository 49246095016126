import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { updateSearchbarTerm } from '../../store/ducks/customComponents';
import useStyles from './styles';

const SearchBar = ({ name, label, updateSearchbarTerm}) => {

  const classes = useStyles();

  useEffect(() => 
    () => updateSearchbarTerm(''),
    [updateSearchbarTerm]
  );

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      classes={{ root: classes.searchBarTextField }}
      onChange={e => updateSearchbarTerm(e.target.value)}
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <SearchIcon color="secondary" />
          </InputAdornment>
      }}
    />
  );
};

const mapDispatchToProps = {
  updateSearchbarTerm
};

export default connect(
  null, 
  mapDispatchToProps
)(SearchBar);
