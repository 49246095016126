import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formContainer: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5]
  },

  formLeftSide: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`
  },

  formRightSide: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  formSubtitleContainer: {
    padding: '15px 35px'
  },

  formSubtitle: {
    fontSize: '.7rem',
    opacity: 0.6
  },

  fieldsContainer: {
    padding: '25px 40px'
  },

  addPhoneField: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'center'
  },

  button: {
    borderRadius: 100,
    fontSize: '0.775rem',
    padding: '4px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '7px 26px',
    }
  },

  buttonContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  roundedButton: {
    borderRadius: 100
  }

}));
