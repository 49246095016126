// Types
export const Types = {
  SET_CUSTOMERS_LOADING: 'SET_CUSTOMERS_LOADING',
  ASYNC_FETCH_CUSTOMERS: 'ASYNC_FETCH_CUSTOMERS',
  ASYNC_FETCH_PAGINATED_CUSTOMERS: 'ASYNC_FETCH_PAGINATED_CUSTOMERS',
  SUCCESS_FETCH_CUSTOMERS: 'SUCCESS_FETCH_CUSTOMERS', 
  ASYNC_SEARCH_CUSTOMERS: 'ASYNC_SEARCH_CUSTOMERS',
  ASYNC_FIND_CUSTOMER: 'ASYNC_FIND_CUSTOMER',
  SUCCESS_FIND_CUSTOMER: 'SUCCESS_FIND_CUSTOMER'
};

const INITIAL_VALUES = {
  data: [],
  selectedCustomer: null, 
  loading: false,
  searchTerm: ''
};

// Reducers
export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_CUSTOMERS_LOADING:
      return { ...state, loading: action.payload.loading };
    case Types.SUCCESS_FETCH_CUSTOMERS:
      return { ...state, data: action.payload.customers };
    case Types.SUCCESS_FIND_CUSTOMER:
        return { ...state, selectedCustomer: action.payload.customer };
    default:
      return state;
  }
};

// Action Creators
export const fetchCustomers = () => ({
  type: Types.ASYNC_FETCH_CUSTOMERS
});

export const fetchPaginatedCustomers = (page, perPage) => ({
  type: Types.ASYNC_FETCH_PAGINATED_CUSTOMERS,
  payload: {
    page, perPage
  }
});

export const findCustomer = () => ({
  type: Types.ASYNC_FIND_CUSTOMER
});

export const setCustomers = customers => ({
  type: Types.SUCCESS_FETCH_CUSTOMERS,
  payload: {
    customers
  }
});

export const searchCustomers = searchTerm => ({
  type: Types.ASYNC_SEARCH_CUSTOMERS,
  payload: {
    searchTerm
  }
});
