import React from 'react';
import Container from '@material-ui/core/Container';

import useStyles from './styles';

const CustomContainer = ({ children }) => {

  const classes = useStyles();

  return (
    <Container maxWidth="xl" classes={{ maxWidthXl: classes.customContainerRoot }}>
      {children}
    </Container>
  );
  
};

export default CustomContainer;
