import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  tableHeadCell: {
    color: 'rgb(0, 0, 0)',
    fontWeight: 700
  },
  
  paddingCheckbox: {
    padding: '0 0 0 20px',
    width: 'auto'
  },

  tableRowProgress: {
    padding: 10
  }
}));
