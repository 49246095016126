import api from './api';

export const fetchCustomers = async params => {
  try {

    const { data } = await api().get(
      '/customers', 
      { params }
    );

    return data;

  } catch (err) {

    console.error(err);

    return err.response.data;

  }
};

export const findCustomer = async id => {
  try {
    const { data } = await api().get(`/customers/${id}`);

    return data;

  } catch (err) {

    console.error(err);

    return err.response.data;

  }
};
