import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.secondary.main
  },

  error: {
    backgroundColor: theme.palette.error.main
  },

  info: {
    backgroundColor: theme.palette.primary.main
  },

  message: {
    display: 'flex',
    alignItems: 'center'
  },
  
  icon: {
    marginRight: '8px'
  }
}));
