// Types
export const Types = {
  SET_APPLICATIONS_LOADING: 'SET_APPLICATIONS_LOADING',
  ASYNC_FETCH_APPLICATIONS: 'ASYNC_FETCH_APPLICATIONS',
  SUCCESS_FETCH_APPLICATIONS: 'SUCCESS_FETCH_APPLICATIONS'
};

const INITIAL_VALUES = {
  data: [],
  loading: false
};

// Reducers
export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_APPLICATIONS_LOADING:
      return { ...state, loading: action.payload.loading };
    case Types.SUCCESS_FETCH_APPLICATIONS:
      return { ...state, data: action.payload.applications }
    default: {
      return state;
    }
  };
};

// Action Creators
export const fetchApplications = () => ({
  type: Types.ASYNC_FETCH_APPLICATIONS
});
