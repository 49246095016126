import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const Acl = ({ children, permission, showUnauthorized }) => {

  //const [show, setShow] = useState(false);

  const classes = useStyles();

  const userPermissions = useSelector(state => state.auth.permissions);
  const permissionsLoaded = useSelector(state => state.auth.permissionsLoaded);

  const renderUnauthorized = useCallback(
    () => (
      <Grid 
        container 
        justify="center" 
        alignItems="center" 
        classes={{ root: classes.unauthorizedContainer }}
      >        
        <Grid item>
          <Typography variant="h2" align="center">
            403
          </Typography>
          <Typography variant="h3" align="center">
            Acesso não autorizado
          </Typography>
        </Grid>
      </Grid>
    ),
    [classes]
  );

  if(!permissionsLoaded) {
    return null
  }

  const show = userPermissions.includes(permission);

  if (!show && showUnauthorized)
    return renderUnauthorized(classes);
  if (!show && !showUnauthorized)
    return null;

  return children;

};

export default Acl;
