import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Dialog from "../../../components/alert/dialog";
import useStyles from "./styles";

import { reduxForm, Field } from "redux-form";
import { InputOutlined, PrimaryButton } from "../../../shared/form";
import { submitForgotPassword, types } from "../../../store/ducks/password";

import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © Copyright. Desenvolvido por UNICADTEC. Todos os direitos reservados.
    </Typography>
  );
}

const dialogError = ({ submitFailed, error }) =>  {

  console.log({submitFailed, error })

  if(submitFailed && error) {

    if(error.hasOwnProperty('errors')) {
      return (
        <Dialog
          title="Não foi possivel processar sua solicitação"
          subTitle={
            <>            
              {error.errors}              
              <br/>
              Para saber mais, entre em contato com o gestor da empresa.          
            </>
          }
          closeText="Fechar"          
        />
      )
    }

    return (
      <Dialog
        title="E-mail inválido ou não encontrado"
        subTitle="Por favor entre em contato com o gestor da empresa e informe seu usuário e um e-mail válido para recuperação de senha."
        closeText="Fechar"          
      />
    )
  }
}

let ForgotPassword = props => {

  const isSended = useSelector(state => state.password.sended);
  
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      
      {dialogError(props)}

      <Hidden only="xs">
        <Grid item md={3} lg={4} />
      </Hidden>
      <Grid item xs={12} md={6} lg={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <img src="https://www.grupounicad.com.br/assets/img/logo-Unicad-01.png" alt="Logo da Unicad"/> */}
          <img
            src="https://www.grupounicad.com.br/assets/img/logo_UNICAD.png"
            style={{ maxWidth: 260 }}
            alt="Logo da Unicad"
          />

          <Typography component="h1" variant="h5" style={{ marginTop: 40 }}>
            Esqueci minha senha
          </Typography>
          {isSended ? (
            <Typography
              component="h4"
              variant="h6"
              style={{ marginTop: 50, textAlign: "left", color: "#7f7f7f" }}
            >
              Solicitação de alteração de senha realizada com successo. Por
              favor, Verifique seu e-mail.
            </Typography>
          ) : (
            <form
              className={classes.form}
              noValidate
              onSubmit={props.handleSubmit(props.submitForgotPassword)}
            >
              <Field
                component={InputOutlined}
                margin="normal"
                required
                fullWidth
                id="username"
                autoFocus
                inputLabel="Usuário ou E-mail"
                placeholder="Digite seu usuário ou e-mail"
                name="username"
                autoComplete="username"
              />

              <PrimaryButton
                type="submit"
                text="Enviar"
                loading={props.submitting}
                fullWidth
                className={classes.submit}
              />

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
//const mapStateToProps = state => {};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ submitForgotPassword }, dispatch);

ForgotPassword = reduxForm({
  form: types.FORM_FORGOT_PASSWORD
  //enableReinitialize: true
})(ForgotPassword);

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);
