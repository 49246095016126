export default variable => {
  const vars = {
    passport_url: process.env.REACT_APP_PASSPORT_URL,
    passport_client_id: process.env.REACT_APP_PASSPORT_CLIENT_ID,
    passport_client_secret: process.env.REACT_APP_PASSPORT_CLIENT_SECRET,
    route_basename: process.env.REACT_APP_ROUTE_BASENAME,
    api_url: process.env.REACT_APP_API_URL,
    sso: process.env.REACT_APP_SSO_URL,
  };

  if(!(variable in vars)) {
    throw new Error('This variable don\'t exists in config');
  }

  return vars[variable];
}