import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  loginRoot: {
    height: '100vh'
  },

  loginBox: {
    width: 350
  },

  form: {
    marginTop: 62,
    marginBottom: 8
  },

  loginHeader: {
  },

  circularProgress: {
    color: theme.palette.common.white
  },

  forgotPasswordLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&.hover': {
      textDecoration: 'underline'
    }
  }
}));