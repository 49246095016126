import { makeStyles } from '@material-ui/core/styles';

export const useInputStyles = makeStyles(() => ({
  root: {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: '2px'
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: '2px'
    }
  },

  input: {
    padding: '12.5px 14px',
    fontSize: '0.8rem'
  }
}));

export const useLabelStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,

    '&$focused': {
      color: theme.palette.secondary.main
    }
  },

  focused: {
    color: theme.palette.secondary.main
  }
}));

export const useHelperTextStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main
  }
}));
