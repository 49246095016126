import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  customContainerRoot: {
    backgroundColor: '#EEE',
    flexGrow: 1,
    paddingBottom: '28px',

    '@media (min-width: 960px)': {
      paddingLeft: '70px',
      paddingRight: '70px'
    },

    '@media (min-width: 600px)': {
      paddingLeft: '62px',
      paddingRight: '62px'
    }
  }
}));
