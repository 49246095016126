import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  separator: {
    color: 'rgb(0, 0, 0)',
    fontWeight: 700
  },

  link: {
    cursor: 'pointer',
    fontWeight: 300,
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.7
    }
  }
}));
