const loginFormValidate = values => {
  const errors = {};

  if (!values.username)
    errors.username = 'Campo obrigatório';
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+[A-Z]{2,4}$/i.test(values.username))
    errors.username = 'Insira um email válido';

  if (!values.password)
    errors.password = 'Campo obrigatório';

  return errors;
};

export default loginFormValidate;
