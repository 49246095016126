import React, { useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useInputStyles, useLabelStyles, useHelperTextStyles } from './styles';

const CustomTextField = ({ 
  input, 
  label, 
  placeholder, 
  margin, 
  helperText, 
  isPassword, 
  adornment,
  parentAdornmentHandleClick,
  meta: { touched, error } 
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const inputClasses = useInputStyles();
  const labelClasses = useLabelStyles();
  const helperTextClasses = useHelperTextStyles();

  const renderAdornment = useCallback(
    () => {
      if (!isPassword && !adornment)
        return null;

      return (
        <InputAdornment position="end">
          {isPassword &&
            <IconButton 
              aria-label="toggle password visibility" 
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
          {adornment &&
            <IconButton 
              aria-label="icon" 
              onClick={parentAdornmentHandleClick}
            >
              {adornment}
            </IconButton>
          }
        </InputAdornment>
      );
    },
    [isPassword, adornment, showPassword, parentAdornmentHandleClick]
  );

  const hasError = useCallback(
    (touched, error) => {
      if (touched && error)
        return true;
  
      return false;
    },
    []
  );

  return (
    <TextField
      {...input}
      fullWidth
      variant="outlined"
      label={label}
      placeholder={placeholder}
      margin={margin}
      helperText={hasError(touched, error) ? error : helperText}
      error={hasError(touched, error)}
      InputProps={{
        classes: inputClasses,
        type: isPassword && !showPassword ? 'password' : 'text',
        endAdornment: renderAdornment()
      }}
      InputLabelProps={{
        classes: labelClasses,
        shrink: true
      }}
      FormHelperTextProps={{
        classes: helperTextClasses
      }}
    />
  );

};

export default CustomTextField;
