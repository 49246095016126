
export default theme => ({
  userControlRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 0 15px 15px',
    height: '100%'
  },
  userContentContainer: {
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5]
  },
  formContainer: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5],
  },

  containerPadding: {
    padding: 20,
    paddingBottom: 8
  },

  button: {
    fontSize: '0.775rem',
    padding: '4px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '7px 26px',
    }
  },

  buttonContainer: {
    padding: 10,
    marginBottom: 30,
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  roundedButton: {
    borderRadius: 100
  },

  cardInverse: { background: theme.palette.secondary.main, padding: 20, color: '#FFF' },

  formSubtitleContainer: {
    padding: '15px 20px'
  },

  formSubtitle: {
    fontSize: '.7rem',
    opacity: 0.6
  },

});
