import React, { Fragment } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const byteToMByte = number => (number / 1024 / 1024).toFixed(3);

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export default ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { error, touched },
  label = 'Selecione um arquivo',
  ...props
}) => {
  const classes = useStyles();

  const id = `${Math.floor(Date.now() / 1000)}-${inputProps.name}`;

  return (
    <Card
      className={classes.containerFile}
      style={touched ? { color: "red", borderColor: "red" } : {}}
    >
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        id={id}
        {...props.input}
        {...props}
      />

      <label htmlFor={id} style={{ float: 'left' }}>
        <Button variant="contained" component="span">
          {label}
        </Button>
      </label>

      {/* <span style={{ marginLeft: 10, marginTop: 8, float: 'left' }}>Formatos Aceitos: {props.accept.split(',').join(' ')}</span> */}

      <Typography component="span" className={classes.fileInfo}>
        {touched && error ? (
          <span style={{ color: "red" }}>Nenhuma arquivo selecionado</span>
        ) : omitValue ? (
          <Fragment>
            {omitValue.name}
            <strong> - ({byteToMByte(omitValue.size)}Mb)</strong>
          </Fragment>
        ) : (
          "Nenhuma arquivo selecionado"
        )}
      </Typography>
    </Card>
  );

};