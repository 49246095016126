import { all, takeLatest, put, call } from "redux-saga/effects";
import { types } from "../ducks/password";

import * as service from "../../services/password";

import { startSubmit, stopSubmit, setSubmitSucceeded } from "redux-form";
import { Types as TypeMessage } from "../ducks/alert";

function* forgotPassword({ payload }) {

  yield put(startSubmit(types.FORM_FORGOT_PASSWORD));

  let errors = {};

  if (payload && payload.username) {

    try {
      const response = yield call(
        service.sendUsernameForForgotPassword,
        payload.username
      );

      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: response.data.message, variant: "success" }
      });

      yield put({ type: types.FORGOT_PASSWORD_SENDED, payload: true })

    } catch ({ response }) {

      if (response.status === 400) {
        errors.username = ["E-mail ou username inválido."];
      }
      if(response.status === 403) {                
        //errors.username = response.data.data;
        errors._error = response.data.data;
      }
      
    }
  } else {
    errors.username = ["Campo obrigatório"];
    errors._error = true;
  }

  yield put(stopSubmit(types.FORM_FORGOT_PASSWORD, errors));
}

function* resetPassword({ payload }) {
  yield put(startSubmit(types.FORM_RESET_PASSWORD));

  let errors = {};
  if (
    payload &&
    (payload.token &&
      payload.email &&
      payload.password &&
      payload.passwordConfirmation)
  ) {
    try {
      yield call(service.resetPasswordRequest, payload);
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: "Senha alterada com sucesso !", variant: "success" }
      });
      yield put(stopSubmit(types.FORM_RESET_PASSWORD));
      return yield put(setSubmitSucceeded(types.FORM_RESET_PASSWORD));
    } catch ({ response }) {
      if (response.status === 400) {
        if (response.data.data)
          yield put({
            type: TypeMessage.MESSAGE,
            payload: { message: response.data.data.message, variant: "error" }
          });
        errors.email = [];
        errors.password = [];
        errors.passwordConfirmation = [];
      }

      if (response.status === 422) {
        if (response.data && response.data.errors)
          errors = response.data.errors;
        else {
          errors.email = [];
          errors.password = [];
          errors.passwordConfirmation = [];
        }
      }
    }
  } else {
    errors.email = payload && !payload.email ? ["Campo obrigatório"] : null;
    errors.password =
      payload && !payload.password ? ["Campo obrigatório"] : null;
    errors._error = true;
    errors.passwordConfirmation =
      payload && !payload.passwordConfirmation ? ["Campo obrigatório"] : null;
  }
  yield put(stopSubmit(types.FORM_RESET_PASSWORD, errors));
}

export default function* passwordSaga() {
  yield all([
    takeLatest(types.ASYNC_FORGOT_PASSWORD_SENDING, forgotPassword),
    takeLatest(types.ASYNC_RESET_PASSWORD_SENDING, resetPassword)
  ]);
}
