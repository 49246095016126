import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import CustomBreadcrumbs from "./CustomBreadcrumbs";

import { useSelector } from "react-redux";

const Header = ({ title, breadcrumbsConfig }) => {
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);

  let logo = user && user.customer.logoUrl;

  if (!logo) {
    logo = "https://www.grupounicad.com.br/assets/img/logo_UNICAD.png";
  }

  return (
    <Box className={classes.headerRoot}>
      <Box className={classes.menuBar}>
        <Box component="img" src={logo} alt="logo" className={classes.logo} />
        <Box>
          <Typography variant="h6" color="primary" style={{ fontWeight: 300 }}>
            {title}
          </Typography>
          <CustomBreadcrumbs breadcrumbsConfig={breadcrumbsConfig} />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
