import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';

import authReducer, { authPersistConfig } from './auth';
import routeReducer, { routePersistConfig } from './route';
import customComponentsReducer from './customComponents';
import customerReducer from './customer';
import tableReducer from './table';
import userReducer from './user';
import roleReducer from './role';
import applicationReducer from './application';
import passwordReducer from './password';
import alertReducer from './alert';

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  route: persistReducer(routePersistConfig, routeReducer),
  customComponents: customComponentsReducer, 
  customers: customerReducer,
  table: tableReducer,
  form: formReducer,
  users: userReducer,
  roles: roleReducer,
  applications: applicationReducer,
  password: passwordReducer,
  alert: alertReducer
});

export default reducers;
