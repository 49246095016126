import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBarSpace: theme.mixins.toolbar,

  drawerContent: {
    width: 300
  },

  userInfo: {
    padding: '30px 30px 22px 30px'
  },

  userInfoName: {
    fontWeight: 'bold',
    marginBottom: 6
  },

  userInfoEmail: {
    fontSize: '0.75rem',
    color: '#999',
    wordWrap: 'break-word'
  },

  listItem: {
    padding: '12px 48px'
  }
}));
