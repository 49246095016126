import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from "./styles";
import CustomTextField from '../../components/CustomTextField';
import CustomSnackbar from '../../components/CustomSnackbar';
import loginFormValidate from '../../validators/loginFormValidate';
import { authenticate } from '../../store/ducks/auth';

let Login = ({ 
  handleSubmit, 
  authenticate, 
  loading, 
  token, 
  currentRoute,
  sso
}) => {

  const classes = useStyles();

  return (
    <>
      {token &&
        <Redirect to={currentRoute} />
      }
      {!token &&
        <Grid 
          container 
          alignItems="center"
          justify="center"
          classes={{ root: classes.loginRoot }}
        >
          {sso &&
            <Grid 
              item 
              xs={12}
              container
              justify="center" 
              alignItems="center" 
              classes={{ root: classes.loginSsoBox }}
            >
              <Grid item classes={{ root: classes.loginBox }}>
                <Grid 
                  item 
                  xs={12} 
                  container 
                  justify="center"
                >
                  <CircularProgress color="secondary" />
                </Grid>

                <Grid 
                  item 
                  xs={12} 
                  container 
                  justify="center"
                >
                  <Typography variant="h5">
                    Validando suas credenciais
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }
          {!sso &&
            <Grid
              item
              classes={{ root: classes.loginBox }}
            >

              <Box
                className={classes.loginHeader}
              >
                <Typography
                  variant="h3"
                  color="primary"
                  align="center"
                  style={{ fontWeight: 300 }}
                >
                  <Box component="span" style={{ color: '#009688' }}>U</Box>Admin
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  style={{ fontWeight: 300 }}
                >
                  Gerência de usuários e permissões
                </Typography>
              </Box>

              <Grid 
                container
                spacing={3}
                direction="column"
                component="form"
                classes={{ root: classes.form }}
                onSubmit={handleSubmit(values => authenticate(values))}
              >
                <Grid item container>
                  <Field
                    component={CustomTextField}
                    name="username"
                    label="Login"
                    placeholder="Entre com seu login"
                    helperText="Email do usuário"
                  />
                </Grid>

                <Grid item container>
                  <Field 
                    component={CustomTextField}
                    name="password"
                    label="Senha"
                    placeholder="Entre com sua senha"
                    helperText="Senha do usuário"
                    isPassword
                  />
                </Grid>

                <Grid item container>
                  <Button 
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                  >
                    {loading ? <CircularProgress classes={{ colorPrimary: classes.circularProgress }} size={26} /> : 'Entrar'}
                  </Button>
                </Grid>
              </Grid>

              <Typography align="center">
                <Link to="password/forgot" className={classes.forgotPasswordLink }>
                  Esqueci minha senha
                </Link>
              </Typography>

            </Grid>
          }

          <CustomSnackbar variant="error" />
        </Grid>
      }
    </>
  );

};

const mapStateToProps = state => ({ 
  loading: state.auth.loading,
  token: state.auth.token,
  currentRoute: state.route.currentRoute
});

const mapDispatchToProps = {
  authenticate
};

Login = connect(
  mapStateToProps, 
  mapDispatchToProps
)(Login);

export default reduxForm({
  form: 'login',
  validate: loginFormValidate
})(Login);
