import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  actionButton: {
    fontWeight: 500,
    textTransform: 'uppercase'
  },

  deleteButton: {
    color: theme.palette.error.main
  }
}));
