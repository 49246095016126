import storage from 'redux-persist/lib/storage';

// Types
export const Types = {
  UPDATE_CURRENT_ROUTE: 'UPDATE_CURRENT_ROUTE',
  UPDATE_PREVIOUS_ROUTE: 'UPDATE_PREVIOUS_ROUTE',
  RESET_ROUTES: 'RESET_ROUTES'
};

// Reducer
const INITIAL_VALUES = {
  currentRoute: '/',
  previousRoute: '/'
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.UPDATE_CURRENT_ROUTE:
      return { ...state, currentRoute: action.payload.route };
    case Types.UPDATE_PREVIOUS_ROUTE:
      return { ...state, previousRoute: action.payload.route };
    case Types.RESET_ROUTES:
      return { ...INITIAL_VALUES };
    default:
      return state;
  }
}

// Action Creators
export const updateCurrentRoute = route => ({
  type: Types.UPDATE_CURRENT_ROUTE,
  payload: {
    route
  }
});

export const updatePreviousRoute = route => ({
  type: Types.UPDATE_PREVIOUS_ROUTE,
  payload: {
    route
  }
});

export const routePersistConfig = {
  key: 'route',
  storage
};
