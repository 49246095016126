const apiValidate = errors => {

  const errorObject = {};

  errors.forEach(error => {
    errorObject[error.field] = [error.translatedMessage];
  });

  return errorObject;

};

export default apiValidate;
