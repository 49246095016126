// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './index.css';

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './components/App';
import { store, persistor } from "./store";
import Alert from './components/alert';

import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <Alert/>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);