import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  footerRoot: {
    textAlign: 'center',
    padding: '30px 0',
    color: 'rgba(0, 0, 0, .5)',
    fontSize: '0.75rem',
    backgroundColor: '#FFF'
  },

  companyName: {
    textDecoration: 'underline',
    textTransform: 'uppercase'
  }
}));
