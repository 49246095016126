import { all, takeLatest, delay, put } from 'redux-saga/effects';

import { Types as CustomComponentsTypes } from '../ducks/customComponents';

function* asyncUpdateSearchbarTerm({ payload }) {
  yield delay(1000);

  const { searchbarTerm } = payload;

  yield put({ type: CustomComponentsTypes.UPDATE_SEARCHBAR_TERM, payload: { searchbarTerm } });
};

export default function* customComponentsSaga() {
  yield all([
    takeLatest(CustomComponentsTypes.ASYNC_UPDATE_SEARCHBAR_TERM, asyncUpdateSearchbarTerm)
  ])
};