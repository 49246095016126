// Types
export const Types = {
  SET_USERS_LOADING: 'SET_USERS_LOADING',
  ASYNC_FETCH_PAGINATED_USERS: 'ASYNC_FETCH_PAGINATED_USERS', 
  SUCCESS_FETCH_USERS: 'SUCCESS_FETCH_USERS',
  FAIL_FETCH_USERS: 'FAIL_FETCH_USERS', 
  ASYNC_POPULATE_USER_FORM: 'ASYNC_POPULATE_USER_FORM', 
  ASYNC_CREATE_USER: 'ASYNC_CREATE_USER',
  ASYNC_UPDATE_USER: 'ASYNC_UPDATE_USER',
  ASYNC_DELETE_USER: 'ASYNC_DELETE_USER',
  ASYNC_IMPORT_USER: 'ASYNC_IMPORT_USER',
  ASYNC_LOAD_LOG_IMPORT_USERS: 'ASYNC_LOAD_LOG_IMPORT_USERS',
  LOG_IMPORT_USERS_LOADED: 'LOG_IMPORT_USERS_LOADED'
};

const INITIAL_VALUES = {
  data: [], 
  loading: false,   
  logLoading: false,
  logData: null
};

// Reducers
export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SET_USERS_LOADING:
      return { ...state, loading: action.payload.loading };
    case Types.SUCCESS_FETCH_USERS:
      return { ...state, data: action.payload.users };
    case Types.FAIL_FETCH_USERS:
      return { ...state, data: [], loading: false };
    case Types.LOG_IMPORT_USERS_LOADED:
      return { ...state, logLoading: action.payload.loading, logData: action.payload.data }
    default:
      return state;
  }
}; 

// Action Creators
export const fetchPaginatedUsers = (page, perPage) => ({
  type: Types.ASYNC_FETCH_PAGINATED_USERS,
  payload: {
    page, perPage 
  }
});

export const populateUserForm = userId => ({
  type: Types.ASYNC_POPULATE_USER_FORM,
  payload: {
    userId
  }
});

export const createUser = formData => ({
  type: Types.ASYNC_CREATE_USER,
  payload: {
    formData
  }
});

export const updateUser = (userId, formData) => ({
  type: Types.ASYNC_UPDATE_USER,
  payload: {
    userId, 
    formData
  }
});

export const deleteUser = userId => ({
  type: Types.ASYNC_DELETE_USER,
  payload: {
    userId
  }
});

export const importUsers = values => ({
  type: Types.ASYNC_IMPORT_USER,
  payload: values
})

export const loadImportUsers = id => ({
  type: Types.ASYNC_LOAD_LOG_IMPORT_USERS,
  payload: { id }
})