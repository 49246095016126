export const types = {
  FORM_FORGOT_PASSWORD: "FORM_FORGOT_PASSWORD",
  FORM_RESET_PASSWORD: "FORM_RESET_PASSWORD",
  ASYNC_FORGOT_PASSWORD_SENDING: "ASYNC_FORGOT_PASSWORD_SENDING",
  ASYNC_RESET_PASSWORD_SENDING: "ASYNC_RESET_PASSWORD_SENDING",
  FORGOT_PASSWORD_SENDED: "FORGOT_PASSWORD_SENDED"
};

const INITIAL_STATE = {
  sended: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FORGOT_PASSWORD_SENDED:
      return { ...state, sended: action.payload };
    default:
      return { ...state };
  }
};

export const submitForgotPassword = values => ({
  type: types.ASYNC_FORGOT_PASSWORD_SENDING,
  payload: values
});

export const submitResetPassword = values => ({
  type: types.ASYNC_RESET_PASSWORD_SENDING,
  payload: values
});
