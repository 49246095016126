import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 400
    }
  },

  palette: {
    primary: {
      main: '#484848',
      contrastText: '#FFF'
    },
    
    secondary: {
      main: '#009688',
      contrastText: '#FFF'
    },
    
    error: {
      main: '#E51919'
    }
  },

  custom: {
    shape: {
      bottomBorderRadius: '0 0 15px 15px'
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
