import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper       from "@material-ui/core/Paper";
import Box         from "@material-ui/core/Box";
import Grid        from "@material-ui/core/Grid";
import Typography  from "@material-ui/core/Typography";
import Hidden      from "@material-ui/core/Hidden";

import useStyles from "./styles";

import { reduxForm, Field } from "redux-form";
import { InputOutlined, PrimaryButton } from "../../../shared/form";
import { submitResetPassword, types } from "../../../store/ducks/password";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © Copyright. Desenvolvido por UNICADTEC. Todos os direitos reservados.
    </Typography>
  );
}

let ResetPassword = props => {
  const classes = useStyles();

  let isFinally = false;

  const hasError = props.error || false;

  if (!props.submitting && props.submitSucceeded && !hasError) {
    isFinally = true;
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />      

      <Hidden only="xs">
        <Grid item md={3} lg={4} />
      </Hidden>
      <Grid item xs={12} md={6} lg={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            src="https://www.grupounicad.com.br/assets/img/logo_UNICAD.png"
            style={{ maxWidth: 260 }}
            alt="Logo da Unicad"
          />
          <Typography component="h1" variant="h5">
            Alterar senha
          </Typography>
          {isFinally ? (
            <Typography component="h4" variant="h6" style={{ marginTop: 50 }}>
              Senha alterada com sucesso.
            </Typography>
          ) : (
            <form
              className={classes.form}
              noValidate
              onSubmit={props.handleSubmit(values =>
                props.submitResetPassword({
                  ...values,
                  token: props.match.params.token
                })
              )}
            >
              <Field
                component={InputOutlined}
                margin="normal"
                required
                fullWidth
                id="email"
                autoFocus
                inputLabel="E-mail"
                placeholder="Digite seu e-mail"
                name="email"
                autoComplete="email"
              />

              <Field
                component={InputOutlined}
                margin="normal"
                required
                fullWidth
                id="password"
                type="password"
                inputLabel="Senha"
                placeholder="Digite sua nova senha"
                name="password"
                autoComplete="password"
              />

              <Field
                component={InputOutlined}
                margin="normal"
                required
                fullWidth
                id="passwordConfirmation"
                type="password"
                inputLabel="Confirmação da nova senha"
                placeholder="Digite novamente sua nova senha"
                name="passwordConfirmation"
                autoComplete="passwordConfirmation"
              />

              <PrimaryButton
                type="submit"
                text="Enviar"
                loading={props.submitting}
                fullWidth
                className={classes.submit}
              />

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ submitResetPassword }, dispatch);

ResetPassword = connect(
  null,
  mapDispatchToProps
)(ResetPassword);

export default reduxForm({
  form: types.FORM_RESET_PASSWORD
  // enableReinitialize: true
})(ResetPassword);
