// Types
export const Types = {
  ASYNC_UPDATE_SEARCHBAR_TERM: 'ASYNC_UPDATE_SEARCHBAR_TERM', 
  UPDATE_SEARCHBAR_TERM: 'UPDATE_SEARCHBAR_TERM',
  HANDLE_DIALOG: 'HANDLE_DIALOG',
  HANDLE_SNACKBAR: 'HANDLE_SNACKBAR'
};

// Reducer
const INITIAL_VALUES = {
  searchbarTerm: '',
  dialogOpen: false,
  snackbarMessage: null
};

export default (state = INITIAL_VALUES, action) => {
  switch(action.type) {
    case Types.UPDATE_SEARCHBAR_TERM:
      return { ...state, searchbarTerm: action.payload.searchbarTerm };
    case Types.HANDLE_SNACKBAR:
        return { ...state, snackbarMessage: action.payload.message };
    case Types.HANDLE_DIALOG:
        return { ...state, dialogOpen: action.payload.open };
    default:
      return state;
  }
};

// Action Creators
export const updateSearchbarTerm = searchbarTerm => ({
  type: Types.ASYNC_UPDATE_SEARCHBAR_TERM,
  payload: {
    searchbarTerm
  }
});

export const handleSnackbar = (message = null) => ({
  type: Types.HANDLE_SNACKBAR,
  payload: {
    message
  }
});

export const handleDialog = open => ({
  type: Types.HANDLE_DIALOG,
  payload: {
    open
  }
});

