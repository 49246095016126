import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  customSelectFieldRoot: {
    [theme.breakpoints.up('md')]: {
      width: '48.5%'
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.secondary.main
    },
  
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main
    },
      
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },

  selectScreen: {
    maxHeight: 'calc(100% - 120px)',
    top: '80px !important'
  }
}));
