import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import theme from "./theme";
import useStyles from './styles';
import Routes from '../../routes';
import Footer from '../Footer';

const App = () => {
  
  const classes = useStyles();

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.appRoot}>
        <Routes />
        <Footer />
      </Box>
    </ThemeProvider>
  );

}

export default App;
