import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import moment from "moment";

import { updatePreviousRoute } from '../../../../store/ducks/route';
import { loadImportUsers } from "../../../../store/ducks/user";
import styles from "./styles";
import CustomTable from "../../../../components/CustomTable";

class UserImportLog extends Component {

  //interval;

  componentDidMount = async () => {
    this.props.loadImportUsers(this.props.match.params.logId);
  };

  componentWillMount() {
    this.props.updatePreviousRoute(this.props.match.url);
  }

  renderSuccessTable = () => {
    const { loading, log } = this.props;
    // Column Header
    const headers = [
      { label: "Linha importada" },
      { label: "#ID" },
      { label: "Nome" },
      { label: "Email" },
      { label: "Cargos Associados" }
    ];

    const body =
      loading === false && log
        ? log.success.map(({ id, line, data }) => {
            return {
              id,
              line,
              identifier: id,
              name: data.name,
              email: data.email,
              roles: (data.roles || []).map(role => role.label).join(", ")
            };
          })
        : [];

    return this.renderTable("Importados com Sucesso", headers, body);
  };

  renderErrorTable = () => {
    const { loading, log } = this.props;
    // Column Header
    const headers = [
      { label: "Linha importada" },
      { label: "Nome" },
      { label: "Email" },
      { label: "Problemas Encontrados" }
    ];

    const body =
      loading === false && log
        ? log.errors.map(({ line, data, errors = {} }, index) => {
            const groupedErrors = [];

            for (let item in errors) {
              if(Array.isArray(errors[item])) {
                groupedErrors.push(errors[item].join(","));
              } else {
                groupedErrors.push(['Erro desconhecido']);
              }
            }

            return {
              id: index,
              line,
              name: data.name,
              email: data.email,
              errors: (
                <Typography color="error" variant="caption">
                  {groupedErrors.join(",")}
                </Typography>
              )
            };
          })
        : [];

    return this.renderTable("Errors na importação", headers, body);
  };

  renderTable = (label, headers, data = []) => {
    const { loading } = this.props;
    return (
      <div style={{ marginTop: 0 }}>
        <Typography variant="h6" style={{ paddingLeft: 12 }}>
          {label} ({data.length})
        </Typography>
        <CustomTable
          tableLoading={loading}
          tableHeaders={headers}
          tableData={loading === false && data ? data : []}
        />
      </div>
    );
  };

  render() {
    const { classes, loading, log } = this.props;

    return (
      <Box className={classes.userControlRoot}>

        <Grid container classes={{ root: classes.userContentContainer }}>
          <Grid
            item
            sm={12}
            style={{ paddingBottom: 10 }}
            classes={{ root: classes.containerPadding }}
          >
            {!loading && log && (
              <Grid container>
                <Grid item sm={3}>
                  <Typography style={{ paddingLeft: 12 }}>
                    <small>Registros Processados:</small>{" "}<br/>
                    {log.total}
                  </Typography>                  
                </Grid>
                <Grid item sm={3}>
                  <Typography style={{ paddingLeft: 12 }}>
                    <small>Iniciado em:</small>{" "}<br/>
                    {moment(log.started_at).format("DD/MM/YYYY HH:mm:ss")}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography style={{ paddingLeft: 12 }}>
                    <small>Concluído em:</small>{" "}<br/>
                    {log.errors.length + log.success.length === log.total ? 
                      moment(log.ended_at).format("DD/MM/YYYY HH:mm:ss")
                    : 'Em andamento'}
                  </Typography>
                </Grid>
                
              </Grid>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            style={{ paddingBottom: 40 }}
            classes={{ root: classes.containerPadding }}
          >
            {this.renderSuccessTable()}
            <div style={{ marginTop: 10 }}></div>
            {this.renderErrorTable()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

UserImportLog = withStyles(styles)(UserImportLog);

const mapStateToProps = state => ({
  previousRoute: state.route.previousRoute, 
  loading: state.users.logLoading,
  log: state.users.logData
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  loadImportUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserImportLog);
