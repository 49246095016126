import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { Types as ApplicationTypes } from '../ducks/application';
import { fetchApplications } from '../../services/application';

function* filterRootPermissions(applications) {

  const isInternalAdmin = yield select(state => state.auth.user.isInternalAdmin);

  const filteredApplications = applications.map(application => {
    const permissions = application.permissions.filter(permission => {
      if (permission.root == 1) {
        if (isInternalAdmin)
          return true;
        else
          return false;
      } else {
        return true;
      }
    });

    return { ...application, permissions };
  });

  return filteredApplications;

};

function* asyncFetchApplications() {

  yield put({ type: ApplicationTypes.SET_APPLICATIONS_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const params = { customerId };

  const { error, data } = yield call(fetchApplications, params);

  if (error)
    yield put({ type: ApplicationTypes.SET_APPLICATIONS_LOADING, payload: { loading: false } });

  const applications = yield filterRootPermissions(data);

  yield all([
      put({ type: ApplicationTypes.SET_APPLICATIONS_LOADING, payload: { loading: false } }),
      put({ type: ApplicationTypes.SUCCESS_FETCH_APPLICATIONS, payload: { applications } })
  ]);

};

export default function* applicationSaga() {

  yield all([
    takeLatest(ApplicationTypes.ASYNC_FETCH_APPLICATIONS, asyncFetchApplications)
  ]);

};