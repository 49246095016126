// Types
export const Types = {
  UPDATE_TABLE_STATUS: 'UPDATE_TABLE_STATUS',
  UPDATE_TABLE_PAGINATION: 'UPDATE_TABLE_PAGINATION',
  RESET_TABLE_STATE: 'RESET_TABLE_STATE'
};

// Reducer
const INITIAL_VALUES = {
  currentPage: 0,
  perPage: 0,
  totalRows: 0,
  lastPage: 0
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.UPDATE_TABLE_STATUS:
      return { ...state, loading: action.payload.loading };
    case Types.UPDATE_TABLE_PAGINATION:
      //console.log(action.payload);      
      return { ...INITIAL_VALUES, ...action.payload };
    case Types.RESET_TABLE_STATE:
      return { ...INITIAL_VALUES };
    default:
      return state;
  }
};

// Action Creators
export const updateTablePagination = () => ({
  type: Types.UPDATE_TABLE_PAGINATION
});

export const resetTableState = () => ({
  type: Types.RESET_TABLE_STATE
});
