import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  userControlRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 0 15px 15px'
  },

  userControlContent: {
    marginTop: 5,
    padding: 10,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5],
  }
}));
