import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import Acl from '../../Acl';

const CustomDrawer = ({ opened, parentHandleItemClick, parentHandleToggle, firstName }) => {

  const classes = useStyles();

  const user = useSelector(state => state.auth.user);

  const renderListItems = useCallback(
    () => {
      const items = [
        { label: 'Clientes', path: '/customers', permission: 'can-list-customers' },
        { label: 'Usuários', path: `/customers/${user.customerId}/users`, permission: 'can-list-users' }, 
        { label: 'Cargos', path: `/customers/${user.customerId}/roles`, permission: 'can-list-roles' }
      ];
    
      return items.map((item, index) => (
        <Acl permission={item.permission} key={index}>
          <ListItem
            button
            disableGutters={false}
            classes={{ root: classes.listItem }}
            onClick={parentHandleItemClick}
            component={Link}
            to={item.path}
          >
            {item.label}
          </ListItem>
        </Acl>
      ))
    },
    [user.customerId, classes.listItem, parentHandleItemClick]
  );

  return (
    <Drawer open={opened} onClose={parentHandleToggle}>
      <Box className={classes.appBarSpace} />
      
      <Box className={classes.drawerContent}>

        <Box className={classes.userInfo}>
          <Typography classes={{ root: classes.userInfoName }}>
            {firstName()}
          </Typography>
          <Typography classes={{ root: classes.userInfoEmail }}>
            {user.email}
          </Typography>
        </Box>

        <List>
          {renderListItems()}
        </List>

      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
