import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';
import { handleSnackbar } from '../../store/ducks/customComponents';

const CustomSnackBar = ({ variant, message, handleSnackbar }) => {

  const classes = useStyles();

  const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: InfoIcon
  };

  const Icon = variantIcon[variant];

  return (
    <Snackbar
      open={message ? true : false}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
      onClose={() => handleSnackbar()}
    >
      <SnackbarContent 
        className={classes[variant]}
        message={
          <Box componrnt="span" className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </Box>
        }
        action={[
          <IconButton 
            key="close" 
            aria-label="close" 
            color="inherit" 
            onClick={() => handleSnackbar()}>
              <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
  
};

const mapStateToProps = state => ({
  message: state.customComponents.snackbarMessage
});

const mapDispatchToProps = {
  handleSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSnackBar);
