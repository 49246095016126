import React from "react";

import FormHelperText from "@material-ui/core/FormHelperText";

export const formatDate = [
  /([0-3])/,
  /([0-9])/,
  "/",
  /([0-1])/,
  /([0-9])/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const formatDateTime = formatDate.concat([
  " ",
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/
]);

export const renderError = ({ error, touched }) => {
  const hasError = (error || false) !== false;
  return (
    touched &&
    hasError &&
    Array.isArray(error) && (
      <FormHelperText id="component-error-text">
        <span>{error.join(", ")}</span>
      </FormHelperText>
    )
  );
};
