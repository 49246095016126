import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { updatePreviousRoute } from '../../store/ducks/route';
import { fetchPaginatedRoles, deleteRole } from '../../store/ducks/role';
import { handleSnackbar, handleDialog } from '../../store/ducks/customComponents';
import useStyles from './styles';
import Acl from '../../components/Acl';
import SearchBar from '../../components/SearchBar';
import CustomTable from '../../components/CustomTable';
import CustomSnackbar from '../../components/CustomSnackbar';
import CustomDialog from '../../components/CustomDialog';
import SelectCustomer from '../../components/SelectCustomer';

const Role = ({ 
  match: { url }, 
  updatePreviousRoute, 
  selectedCustomerId, 
  loading, 
  fetchPaginatedRoles, 
  roles, 
  searchbarTerm,
  deleteRole,  
  handleSnackbar,
  handleDialog
}) => {

  const classes = useStyles();

  const [roleId, setRoleId] = useState(null);

  useEffect(() => {

    fetchPaginatedRoles();

  }, [fetchPaginatedRoles, selectedCustomerId, searchbarTerm]);

  useEffect(() => {

    return () => {
      handleSnackbar();
      updatePreviousRoute(url);
    } 

  }, [handleSnackbar, updatePreviousRoute, url]);

  const formatDataToTable = useCallback(
    () => {
      // Column Content
      const rolesData = roles.map(role => {
        const { id, label, permissions } = role;

        const deleteIconButton = 
          <Acl permission="can-delete-roles">
            <Tooltip title="Excluir" placement="left">
              <IconButton 
                aria-label="deletar" 
                style={{ marginRight: 20 }}
                onClick={() => {
                  handleDialog(true);
                  setRoleId(id);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Acl>;

        const createIconButton = 
          <Acl permission="can-edit-roles">
            <Tooltip title="editar" placement="right">
              <IconButton 
                aria-label="editar"
                component={Link}
                to={`${url}/${id}/update`}
              >
                <CreateIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Acl>;

        const icons = <>{deleteIconButton}{createIconButton}</>;

        return { id, label, permissions: permissions.length, icons };
      });

      // Column Header
      const headers = [
        { label: 'Nome' },
        { label: 'Nº de Permissões' }, 
        { label: (<Box style={{ padding: 24 }}></Box>) }
      ];

      return { headers, rolesData };
    }, 
    [roles, handleDialog, setRoleId, url]
  );

  const dataFormatted = formatDataToTable();

  return (
    <Box className={classes.roleRoot}>

      <Grid
        container
        classes={{ root: classes.roleContentContainer }}
      >
       <SelectCustomer url={url}/>

        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          style={{ padding: 20 }}
        >
          <SearchBar
            name="search-roles"
            label="Pesquisar cargos"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          justify="flex-end"
          classes={{ root: classes.newRoleButtonContainer }}
        >
          <Acl permission="can-create-roles">
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={`${url}/new`}
            >
              Novo Cargo
            </Button>
          </Acl>
        </Grid>

        <Grid
          item
          xs={12}
          classes={{ root: classes.rolesTableContainer }}
        >
          <CustomTable
            tableLoading={loading}
            tablePadding="checkbox"
            tableHeaders={dataFormatted.headers}
            tableData={dataFormatted.rolesData}
            parentHandlePagination={(page, perPage) => fetchPaginatedRoles(page, perPage)}
          />
        </Grid>
      </Grid>

      <CustomDialog
        parentHandleClose={action => {
          if (action === 'Cancelar') {
            setRoleId(null);
            handleDialog(false);
          } else {
            deleteRole(roleId);
            handleDialog(false);
          }
        }}
      />

      <CustomSnackbar variant="success" />

    </Box>
  );

};

const mapStateToProps = state => ({
  selectedCustomerId: state.auth.selectedCustomerId, 
  loading: state.roles.loading, 
  roles: state.roles.data,
  searchbarTerm: state.customComponents.searchbarTerm
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  fetchPaginatedRoles, 
  deleteRole, 
  handleSnackbar,
  handleDialog
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Role);
