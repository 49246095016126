import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  headerRoot: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    padding: '20px 0'
  },

  menuBar: {
    display: 'flex',
    padding: '0 35px 20px 35px'
  },

  logo: {
    //width: '138px',
    //height: 'auto',
    maxHeight: 50,
    marginRight: '40px'
  }
}));
