import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckboxField = ({ input, label }) => (

  <FormControlLabel
    control= {
      <Checkbox 
        {...input}
        checked={Boolean(input.value)}
      />
    }
    label={label}
  />
  
);

export default CustomCheckboxField;
