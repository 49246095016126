import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  
  customerRoot: {
  },
  customerContentContainer: {
    marginTop: '5px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.custom.shape.bottomBorderRadius,
    boxShadow: theme.shadows[5]
  },

  logo: {
    maxHeight: 80,
    padding: 10
  },

  customerTableContainer: {
    borderTop: `1px solid ${theme.palette.divider}`
  }

}));
