import {
  takeLatest,
  all,
  put,
  call,
  select,
  delay,
  take,
  race
} from "redux-saga/effects";

import { Types as AuthTypes } from "../ducks/auth";
import { Types as RouteTypes } from '../ducks/route';
import { Types as CustomComponentsTypes } from "../ducks/customComponents";
import { authenticate, self, ssoSession, logout, ssoRefresh } from "../../services/auth";
import { history } from "../../routes";

import camelCaseKeys from 'camelcase-keys-recursive';

import moment from 'moment'

function* asyncGetLoggedInUser(token) {
  const { error, data: userData } = yield call(self, token.accessToken);

  if (error) {
    yield all([
      call(logout, token.accessToken),
      put({
        type: CustomComponentsTypes.HANDLE_SNACKBAR,
        payload: { message: "Usuário ou senha inválidos" }
      }),
      put({ type: AuthTypes.FAIL_AUTH })
    ]);
  } else {
    const isInternalAdmin = userData.permissions.includes("can-list-customers");

    const redirectUrl = isInternalAdmin
      ? "/customers"
      : `/customers/${userData.customerId}/users`;

    yield all([
      put({
        type: AuthTypes.SUCCESS_AUTH,
        payload: {
          user: { ...userData, isInternalAdmin, permissions: undefined },
          permissions: userData.permissions,
          token
        }
      }),
      put({
        type: AuthTypes.UPDATE_SELECTED_CUSTOMER,
        payload: { customerId: userData.customerId }
      }),
      call(history.push, redirectUrl)
    ]);
  }
}

function* asyncAuthenticate(action) {
  const { data: token, error } = yield call(
    authenticate,
    action.payload.dataForm
  );

  if (error)
    yield all([
      put({
        type: CustomComponentsTypes.HANDLE_SNACKBAR,
        payload: { message: error.message }
      }),
      put({ type: AuthTypes.FAIL_AUTH })
    ]);
  else {
    yield call(asyncGetLoggedInUser, token);
  }
}

function* asyncSSOAuthenticate({ payload: { sso, accessToken } }) {
  const { error, data } = yield call(ssoSession, accessToken);

  if (error) {
    yield all([put({ type: AuthTypes.FAIL_AUTH }), call(history.push, "/")]);
  } else {
    const { user, sso, ...token } = data;

    yield all([
      put({
        type: AuthTypes.SUCCESS_AUTH,
        payload: {
          user: {
            ...user,
            isInternalAdmin: false
          },
          sso: {
            ssoIntegration: sso,
            ssoExpiresAt: token.expiresAt
          },
          token
        }
      }),
      call(history.push, `/customers/${user.customerId}/users`)
    ]);
  }
}

function* asyncFetchPermissions() {
  const accessToken = yield select(state => state.auth.token.accessToken);

  const { data: userData } = yield call(self, accessToken);

  if (userData)
    yield put({
      type: AuthTypes.SUCCESS_FETCH_PERMISSIONS,
      payload: { permissions: userData.permissions }
    });
}

function* asyncLogout() {
  const accessToken = yield select(state => state.auth.token.accessToken);

  const { data } = yield call(logout, accessToken);

  if (data) {
    yield all([
      put({ type: AuthTypes.LOGOUT }),
      put({ type: RouteTypes.RESET_ROUTES }),
      call(history.push, "/")
    ]);
  }
}

function* refreshSSOAuth() {

  while(true) {

    const { hydrate } = yield race({
      hydrate: take('persist/REHYDRATE'),
      successAuth: take(AuthTypes.SUCCESS_AUTH)
    });

    const key = hydrate ? hydrate.key : 'auth';

    let { sso, user, token: tokens } = yield select(state => state.auth);

    if(key === 'auth' && tokens !== null && sso !== null) {
      
      while (true) {
        try {
          if (!user) {          
            yield call(redirectToSSOLogin, sso);
          } else if (sso.ssoExpiresAt) {

            const diffToExpires = moment(sso.ssoExpiresAt).diff(moment(), "minutes");

            if (diffToExpires <= 5) {
              if('refreshToken' in tokens) {
                try {
                  
                  let { user, sso: ssoStr, ...token } = camelCaseKeys((yield call(ssoRefresh, tokens.refreshToken, sso.ssoIntegration)).data);

                  yield all([
                    put({
                      type: AuthTypes.SUCCESS_AUTH,
                      payload: {
                        user: {
                          ...user,
                          isInternalAdmin: false
                        },
                        sso: {
                          ssoIntegration: ssoStr,
                          ssoExpiresAt: token.expiresAt
                        },
                        token
                      }
                    }),
                    call(history.push, `/customers/${user.customerId}/users`)
                  ])                 
                  
                } catch (err) {
                  yield call(redirectToSSOLogin, sso);
                }
              }
            }
          }
        } catch (err) {}

        yield delay(60000);
      }
      
    }    
  }
}

function* redirectToSSOLogin(sso) {
  window.location.href = `${process.env.REACT_APP_SSO_URL}/login?sso=${sso.ssoIntegration}&redirect=${process.env.REACT_APP_URL_APP}/login/sso`;
  yield take("FOREVER");
}

function* selectDefaultCustomer() {

  while(true) {

    const { hydrate } = yield race({
      hydrate: take('persist/REHYDRATE'),
      successAuth: take(AuthTypes.SUCCESS_AUTH)
    });

    const key = hydrate ? hydrate.key : 'auth';

    let { user, selectedCustomerId } = yield select(state => state.auth);

    if(key === 'auth' && user && !selectedCustomerId) {
      yield put({
        type: AuthTypes.UPDATE_SELECTED_CUSTOMER,
        payload: { customerId: user.customerId }
      });
    }
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(AuthTypes.ASYNC_AUTH, asyncAuthenticate),
    takeLatest(AuthTypes.ASYNC_SSO_AUTHENTICATE, asyncSSOAuthenticate),
    takeLatest(AuthTypes.ASYNC_FETCH_PERMISSIONS, asyncFetchPermissions),
    takeLatest(AuthTypes.ASYNC_LOGOUT, asyncLogout),
    selectDefaultCustomer(),
    refreshSSOAuth()    
  ]);
}
