import axios from "axios";

import { store } from "../store";
import config from "../config";

export default () => {
  if (store.getState().auth.token) {
    return axios.create({
      baseURL: config("api_url"),
      headers: {
        authorization: `Bearer ${store.getState().auth.token.accessToken}`
      }
    });
  } else {
    return axios.create({ baseURL: config("api_url") });
  }
};

export const apiSSO = () => axios.create({ baseURL: config('sso') });

export const apiPassport = () => axios.create({ baseURL: config('passport_url') });
