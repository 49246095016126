import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { updatePreviousRoute } from '../../store/ducks/route';
import { fetchPaginatedCustomers } from '../../store/ducks/customer';
import useStyles from './styles';
import SearchBar from '../../components/SearchBar';
import CustomTable from '../../components/CustomTable';

const Customer = ({ 
  loading, 
  updatePreviousRoute, 
  fetchPaginatedCustomers, 
  customers, 
  searchbarTerm,
  match: { url }
}) => {

  const classes = useStyles();

  useEffect(() => {

    fetchPaginatedCustomers();

  }, [fetchPaginatedCustomers, searchbarTerm]);

  useEffect(() => {

    return () => updatePreviousRoute(url)

  }, [updatePreviousRoute, url]);

  const formatDataToTable = useCallback(
    () => {
      // Column Content
      const customersData = customers.map(customer => {
        const { id, name, address } = customer;

        return { id, name, address };
      });

      // Column Header
      const headers = [
        { label: 'Cliente' },
        { label: 'Endereço' }
      ];

      return { headers, customersData };
    }, 
    [customers]
  );

  const tableConfig = formatDataToTable();

  return (
    <Box className={classes.customerRoot}>

      <Grid
        container
        classes={{ root: classes.customerContentContainer }}
      >
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ padding: 20 }}
        >
          <SearchBar
            name="search-customers"
            label="Pesquisar clientes"
          />
        </Grid>

        <Grid
          item
          xs={12}
          classes={{ root: classes.customerTableContainer }}
        >
          <CustomTable
            tableLoading={loading}
            tableHeaders={tableConfig.headers}
            tableData={tableConfig.customersData}
            parentHandlePagination={(page, perPage) => fetchPaginatedCustomers(page, perPage)}
          />
        </Grid>
      </Grid>

    </Box>
  )

};

const mapStateToProps = state => ({
  loading: state.customers.loading, 
  customers: state.customers.data,
  searchbarTerm: state.customComponents.searchbarTerm
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  fetchPaginatedCustomers
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Customer);
