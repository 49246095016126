import { takeLatest, all, put, call, delay, select } from 'redux-saga/effects';

import { Types as CustomerTypes } from '../ducks/customer';
import { Types as TableTypes } from '../ducks/table';
import { fetchCustomers, findCustomer } from '../../services/customer';

function* asyncFetchCustomers() {

  yield put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: true } });

  const { error, data } = yield call(fetchCustomers);

  if (error)
    yield put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: false } });

  yield all([
    put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: false } }),
    put({ type: CustomerTypes.SUCCESS_FETCH_CUSTOMERS, payload: { customers: data } })
  ])

};

function* asyncFetchPaginatedCustomers({ payload }) {

  yield put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: true } });

  let { page = 1, perPage = 10 } = payload;

  const searchTerm = yield select(state => state.customComponents.searchbarTerm);

  let params = { page, perPage, searchTerm };

  let { lastPage, data, total, error } = yield call(fetchCustomers, params);

  if (error) {

    yield put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: false } });

  } else {
    if (lastPage < page) {

      page = lastPage;

      params = { ...params, page };

      ({ data, total } = yield call(fetchCustomers, params));

    }

    yield all([
      put({ type: TableTypes.UPDATE_TABLE_PAGINATION, payload: { totalRows: total, currentPage: page, perPage, lastPage } }),
      put({ type: CustomerTypes.SUCCESS_FETCH_CUSTOMERS, payload: { customers: data } }), 
      put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: false } })
    ]);
  }
};

function* asyncSearchCustomers({ payload }) {
  yield delay(2000);

  const { searchTerm } = payload;

  yield all([
    put({ type: CustomerTypes.UPDATE_SEARCH_TERM, payload: { searchTerm } }),
    put({ type: CustomerTypes.ASYNC_FETCH_PAGINATED_CUSTOMERS, payload: { } })
  ]);
};

function* asyncFindCustomer() {
  yield put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const { data } = yield call(findCustomer, customerId);

  if (data)
    yield all([
      put({ type: CustomerTypes.SET_CUSTOMERS_LOADING, payload: { loading: false } }),
      put({ type: CustomerTypes.SUCCESS_FIND_CUSTOMER, payload: { customer: data } })
    ]);
};

export default function* customerSaga() {
  yield all([
    takeLatest(CustomerTypes.ASYNC_FETCH_PAGINATED_CUSTOMERS, asyncFetchPaginatedCustomers),
    takeLatest(CustomerTypes.ASYNC_FETCH_CUSTOMERS, asyncFetchCustomers),
    takeLatest(CustomerTypes.ASYNC_SEARCH_CUSTOMERS, asyncSearchCustomers),
    takeLatest(CustomerTypes.ASYNC_FIND_CUSTOMER, asyncFindCustomer)
  ]);
};
