import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { updatePreviousRoute } from '../../../store/ducks/route';
import { populateRoleForm, createRole, updateRole } from '../../../store/ducks/role';
import useStyles from './styles';
import RoleForm from './RoleForm';

const RoleControl = ({
  match: { 
    url,
    params: {
      roleId
    }
  },
  updatePreviousRoute, 
  form, 
  applications, 
  populateRoleForm, 
  createRole,
  updateRole
}) => {

  const classes = useStyles();

  useEffect(() => {

    if (roleId)
      populateRoleForm(roleId);
    else
      populateRoleForm();
      
  }, [populateRoleForm, roleId]);

  useEffect(() => {

    return () => updatePreviousRoute(url);

  }, [updatePreviousRoute, url]);

  const configTextFields = useCallback(
    () => ([
      { name: 'label', label: 'Nome', adornment: false }
    ]), 
    []
  );

  return (
    <Box className={classes.roleControlRoot}>

      {!form.role &&
          <Grid
            container
            classes={{ root: classes.roleControlContent }}
          >
            <Grid item xs={12}>
              <LinearProgress color="secondary" />
            </Grid>
          </Grid>
      }

      {form.role &&
          <RoleForm
            textFields={configTextFields()}
            applications={applications}
            parentHandleSubmit={
              values => {
                if (roleId)
                  updateRole(roleId, values);
                else
                  createRole(values);
              }
            }
          />
      }

    </Box>
  );

};

const mapStateToProps = state => ({
  applications: state.applications.data,
  form: state.form,
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  populateRoleForm, 
  createRole,
  updateRole
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(RoleControl);
